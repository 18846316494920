import _ from 'lodash'
import LogRocket from 'logrocket'
import React from 'react'
import paths from 'constants/paths'
import storage from 'constants/storage'
import type { User } from 'constants/types'
import Analytics from 'libs/analytics'
import * as auth from 'libs/auth'
import * as cookie from 'libs/cookie'
import * as logging from 'libs/logging'
import * as logrocket from 'libs/logrocket'
import * as notifications from 'libs/notifications'
import * as subscriptionApiRequest from 'libs/subscription-api-request'
import * as url from 'libs/url'
import * as userLib from 'libs/user'
import * as userApiRequest from 'libs/user-api-request'

type UpdateValue = { user: User | null }
// eslint-disable-next-line no-unused-vars
type UpdateCallback = (state: {}) => void

const STATE_INITIAL: ProviderState = {
  analytics: undefined,
  eventData: {},
  isAuthed: false,
  isEnterprise: false,
  isVideoPlaying: false,
  partner: undefined,
  progress: 0,
  title: undefined,
  /* eslint-disable no-unused-vars */
  update: (value: UpdateValue, callback?: UpdateCallback) => {},
  updateEventData: (value: {}) => {},
  updateProgress: (value: Progress) => {},
  updateTitle: (value: Title) => {},
  updateUser: (value: User | null) => {},
  /* eslint-enable no-unused-vars */
  user: null,
}

const GlobalContext = React.createContext(STATE_INITIAL)
const { Consumer, Provider } = GlobalContext

type ProviderProps = {
  children: React.ReactNode
}

type Progress = number
type Title = string

type ProviderState = {
  analytics: Analytics | undefined
  eventData: {}
  isAuthed: boolean
  isEnterprise: boolean
  isVideoPlaying: boolean
  partner: string | undefined
  progress: Progress
  title: Title | undefined
  /* eslint-disable no-unused-vars */
  update: (value: UpdateValue, callback?: UpdateCallback) => void
  updateEventData: (value: {}) => void
  updateProgress: (value: Progress) => void
  updateTitle: (value: Title) => void
  updateUser: (value: User | null) => void
  /* eslint-enable no-unused-vars */
  user: User | null
}

class GlobalContextProvider extends React.Component<ProviderProps, ProviderState> {
  state: ProviderState = STATE_INITIAL

  componentDidMount() {
    this.getUser()

    const { fromApp } = url.getQueryString()
    if (fromApp) {
      cookie.createCookie(storage.FROM_APP, 'true')
    }
  }

  getUser = async () => {
    try {
      if (!window.location?.pathname?.includes('download')) {
        const { magicToken } = url.getQueryString()
        if (magicToken) await auth.handleMagicToken(magicToken)
      }

      const uid = cookie.getCookie(storage.UID_KEY)

      if (uid === undefined) {
        if (!paths.isUnauthedPage(window.location?.pathname)) {
          await auth.signOut()
        }

        this.setState({
          analytics: Analytics.init(null),
          isAuthed: false,
          user: null,
        })

        return
      }

      let user = null
      if (uid) user = await userApiRequest.getUser()
      const userData = _.get(user, 'data', null)

      let analytics
      if (!this.state.analytics) {
        analytics = Analytics.init(userData)
      } else {
        analytics = this.state.analytics
        this.state.analytics.setUser(userData)
      }

      let subscription
      if (this.state.user?.subscription) subscription = this.state.user.subscription
      else if (!userLib.isEnterpriseUser(userData) && userData.isOnboardingCompleted) {
        const subscriptionResponse = await subscriptionApiRequest.getSubscriptionForUser()
        if (subscriptionResponse.statusCode === 200) subscription = subscriptionResponse.data
      }
      if (subscription) userData.subscription = subscription

      logging.toConsole({
        message: `🧑 USER`,
        data: userData,
      })

      this.setState((prevState) => ({
        analytics,
        isAuthed: true,
        isEnterprise: userLib.isEnterpriseUser(userData) || !!prevState.partner,
        user: userData,
      }))
      this.identifyUser(userData)
    } catch (error) {
      console.error(error)
      notifications.notifyError()
    }
  }

  identifyUser = (user: User) => {
    if (!user) {
      return
    }

    const { isEnterprise } = this.state

    LogRocket.identify(user.id, {
      email: !isEnterprise ? user.email : '',
      firstName: !isEnterprise ? user.firstName : '',
      lastName: !isEnterprise ? user.lastName : '',
      userType: logrocket.getUserType(user.email),
    })

    // if (!_.isUndefined(window.zE)) {
    //   window.zE('webWidget', 'identify', {
    //     email: user.email,
    //     name: `${user.firstName} ${user.lastName}`,
    //   })
    // }
  }

  update = async (value: UpdateValue, callback?: UpdateCallback) => {
    this.setState(value, () => {
      if (callback) callback(this.state)
    })
  }

  updateEventData = async (value = {}) => {
    this.setState((prevState) => ({
      eventData: {
        ...prevState.eventData,
        ...value,
      },
    }))
  }

  updateProgress = (value: Progress) => {
    this.setState({ progress: value })
  }

  updateTitle = (value: Title) => {
    this.setState({ title: value })
  }

  render() {
    return (
      <Provider
        value={{
          ...this.state,
          analytics: this.state.analytics,
          update: this.update,
          updateEventData: this.updateEventData,
          updateProgress: this.updateProgress,
          updateTitle: this.updateTitle,
          updateUser: this.getUser,
        }}>
        {this.props.children}
      </Provider>
    )
  }
}

type ConsumerProps = {
  // eslint-disable-next-line no-unused-vars
  children: (store: {}) => React.ReactNode
}

function GlobalContextConsumer({ children }: ConsumerProps) {
  return <Consumer>{(store) => children(store)}</Consumer>
}

const useGlobalContext = () => React.useContext(GlobalContext)

export default GlobalContext
export { GlobalContextConsumer, GlobalContextProvider, useGlobalContext }
