import { AnalyticsBrowser } from '@segment/analytics-next'
import type { User } from 'constants/types'
import * as logging from 'libs/logging'

// NODE_ENV doesn't correctly reflect the environment for us.
// Use GATSBY_BOLD_APP_ENV to determine the key so we can still
// use the dev key on staging.
let segmentKey = 'EyFdHPJYiv3yLdB3TYt2mYiFyTx1UDgW'

if (process.env.GATSBY_BOLD_APP_ENV !== 'production') {
  segmentKey = 'zHMDhdU0cr4tsOhbO2ftYi8YoqAeuJKa'
}

export const analytics = AnalyticsBrowser.load(
  {
    writeKey: segmentKey,
    cdnURL: 'https://static-sgmnt.agebold.com',
  },
  {
    obfuscate: true,
    integrations: {
      'Segment.io': {
        apiHost: 'sgmnt.agebold.com/v1',
        protocol: 'https',
      },
    },
  }
)

function logToConsole({ data, message }: { data?: string | {}; message: string }) {
  logging.toConsole({
    message: `🎉 SEGMENT - ${message}`,
    data,
  })
}

/**
 * SEGMENT DIRECT INTERACTION FUNCTIONS (these methods should remain private)
 */

export function page(pageName: string, properties: {}) {
  logToConsole({
    message: `PAGE - ${pageName}`,
    data: properties,
  })
  analytics?.page(pageName, properties, { context: { ip: '0.0.0.0' } })
}

// REQUIRED FIELD: ID
export function identify(user: User) {
  logToConsole({
    message: `IDENTIFY - ${user.id}`,
    data: user,
  })
  analytics?.identify(user.id, user, { context: { ip: '0.0.0.0' } })
}

export function reset() {
  logToConsole({ message: 'RESET IDENTITY' })
  analytics?.reset()
}

export function track(event: string, properties: {}) {
  logToConsole({
    message: `TRACK - ${event}`,
    data: properties,
  })
  analytics?.track(event, properties, { context: { ip: '0.0.0.0' } })
}
