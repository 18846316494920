import { navigate } from 'gatsby'
import _ from 'lodash'
import qs from 'qs'
import paths from 'constants/paths'
import storage from 'constants/storage'
import { User } from 'constants/types'
import * as api from 'libs/api'
import * as cookie from 'libs/cookie'
import * as enterprise from 'libs/enterprise'
import * as segment from 'libs/segment'
import * as url from 'libs/url'
import * as userLib from 'libs/user'
import * as userApiRequest from 'libs/user-api-request'

export const FORGOT_PASSWORD_INTRODUCTION_COPY =
  'If you forgot your password, enter the email address you used to sign up—we’ll send you an email with a link to reset your password.'
export const FORGOT_PASSWORD_SUCCESS_COPY =
  'We sent you a link to reset your password to your email. This link will expire in 10 minutes.'

function clearSessionData(user?: User) {
  cookie.deleteCookie(storage.EMAIL_KEY)
  cookie.deleteCookie('programId')
  cookie.deleteCookie(storage.PROMO_CODE_COUPON_KEY)
  cookie.deleteCookie(storage.PROMO_CODE_KEY)
  cookie.deleteCookie(storage.REFRESH_TOKEN_KEY)
  cookie.deleteCookie(storage.ACCESS_TOKEN_KEY)
  cookie.deleteCookie(storage.UID_KEY)

  if (userLib.isEnterpriseTerminatedUser(user)) {
    cookie.deleteCookie(storage.HOME_KEY)
  }
  // TODO: currently called on first 'failed' signed out api request
  //removeStoredAssessmentInformation()
}

function getRefreshToken() {
  return cookie.getCookie(storage.REFRESH_TOKEN_KEY) || null
}

export function determineSignUp() {
  // email only from certain LPs, or reef/orca LP with a code in URL
  const externalId = cookie.getCookie(storage.ENTERPRISE_EXTERNAL_ID_KEY)
  const partner = cookie.getCookie(storage.HOME_KEY)
  if (
    (externalId && (partner === enterprise.REEF_KEY || partner === enterprise.ORCA_KEY)) ||
    enterprise.PARTNERS_ALWAYS_ELIGIBLE.includes(partner)
  ) {
    return 'email'
  }
  return 'all'
}

export function setSessionData(responseData: {
  accessToken?: string
  refreshToken?: string
  user: { email?: string; id?: string }
}) {
  cookie.createCookie(storage.ACCESS_TOKEN_KEY, responseData.accessToken || '')
  cookie.createCookie(storage.REFRESH_TOKEN_KEY, responseData.refreshToken || '')
  cookie.createCookie(storage.EMAIL_KEY, responseData.user?.email || '')
  cookie.createCookie(storage.UID_KEY, responseData.user?.id || '')
}

/**
 * Exchange magic token to the backend for refresh and access tokens in order to authenticate normally
 */
export async function handleMagicToken(magicToken: string) {
  try {
    const urlWithoutMagicToken = url.generateUrlWithoutQueryStringKeyValue('magicToken')
    let userId = null
    if (getRefreshToken() == null) {
      const result = await userApiRequest.exchangeMagicTokenForCredentials(magicToken)
      if (result.statusCode === 200 && result.data) {
        setSessionData(result.data)
        userId = result.data.user?.id
      }
    }
    //fetch within api.post seems to get rid of query parameters from url...unsure why...but we keep them now
    window.history.replaceState({}, '', urlWithoutMagicToken)
    window.location.reload()
    // set userId for future segment calls...magic login was breaking this
    if (userId) segment.analytics.user().id(userId)
  } catch (error) {
    return { error }
  }
}

export async function refreshTokens() {
  const refreshToken = getRefreshToken()
  if (!refreshToken) {
    return
  }
  const result = await api.post('refreshTokens', { refreshToken })
  if (result.statusCode === 200 && result.data) {
    cookie.createCookie(storage.REFRESH_TOKEN_KEY, result.data.refreshToken)
    cookie.createCookie(storage.ACCESS_TOKEN_KEY, result.data.accessToken)
    return result
  }
  await userApiRequest.signOutUser()
  clearSessionData()
  navigate(`${paths.SIGN_IN}?expired=true`)
}

// TODO: should hit api to blacklist refresh token
export async function signOut(options: unknown, user: User) {
  const error = _.get(options, 'error', null)
  const intendedUrl = window.location.href
  await userApiRequest.signOutUser()
  clearSessionData(user)

  const signOutOptionsWithRedirect = {
    error: error || undefined,
    redirect: intendedUrl,
  }

  window.location = `${paths.SIGN_IN}?${qs.stringify(signOutOptionsWithRedirect)}`
}
