import * as environment from 'libs/environment'

type Coupon = {
  amount_off?: number
  name: string
  percent_off?: number
}

export type Plan = {
  amount: number
  features: string[]
  id: string
  interval: string
  key: string
  name: string
}

type Plan2 = {
  amount: number
  eligible_coupons: Array<Coupon>
  id: string
  interval_count: number
  interval: string
}

export const PROMO_CODES = {
  _2020: environment.isProduction ? 'TatOyXiQ' : 'BlbolDk9',
  EARLY: 'EARLY50',
}
export const TRIAL_DAYS = 14

const featuresPremium = [
  'Personalized programs and support from Bold’s expert coaches',
  'Assess your balance, strength and mobility as you progress',
  'Access on-demand library of strength, cardio, yoga, pilates classes',
  'Attend live classes and premium experiences with experts and coaches',
]

// order matters here
export const PLANS = {
  basic: {
    key: 'basic',
    name: 'Basic',
    amount: 0,
    features: [
      'Try a limited selection of five Bold classes',
      'Assess your balance, strength and mobility',
      'Access a limited set of live classes',
    ],
    id: !environment.isProduction ? 'plan_GCRycsHSY6cuBv' : 'price_1KTvouH7hkV8tQE3ZQAcpOvx',
    interval: 'month',
  },
  month: {
    key: 'month',
    name: 'Monthly',
    name2: 'Monthly',
    amount: 3499,
    features: featuresPremium,
    billingFrequency: 'monthly',
    id: environment.isProduction
      ? 'price_1OK2LhH7hkV8tQE3H4NR7h8C'
      : 'price_1OJipIH7hkV8tQE3p046Tzh5',
    interval: 'month',
    freeTrialDays: TRIAL_DAYS,
  },
  biAnnual: {
    key: 'biAnnual',
    name: '6 Months',
    name2: '6 Months',
    amount: 15_699,
    features: featuresPremium,
    billingFrequency: 'bi-annually',
    id: environment.isProduction
      ? 'price_1OK2LIH7hkV8tQE3VJVIAcgF'
      : 'price_1OJimVH7hkV8tQE3ocRqaxp2',
    interval: 'biAnnual',
    freeTrialDays: TRIAL_DAYS,
  },
  year: {
    key: 'year',
    name: '12 Months',
    name2: 'Annual',
    amount: 24_899,
    features: featuresPremium,
    billingFrequency: 'annually',
    id: environment.isProduction
      ? 'price_1OK2KpH7hkV8tQE3Piza0sws'
      : 'price_1OJiRuH7hkV8tQE34zk9EtxV',
    interval: 'year',
    freeTrialDays: TRIAL_DAYS,
  },
}

const isPlan = (plan: Plan2) => plan && plan.interval

export const isAnnualPlan = (plan: Plan2) =>
  isPlan(plan) && plan.interval === 'year' && plan.amount !== 0

export const isBiAnnualPlan = (plan: Plan2) =>
  isPlan(plan) && plan.interval === 'month' && plan.interval_count === 6 && plan.amount !== 0

export const isFreePlan = (plan: Plan2) =>
  isPlan(plan) && plan.interval === 'month' && plan.amount === 0

export const isMonthlyPlan = (plan: Plan2) =>
  isPlan(plan) && plan.interval === 'month' && plan.interval_count === 1 && plan.amount !== 0

export function calculateAmountInCentsWithCoupon(
  amount: number,
  coupon?: Coupon,
  plan?: Plan
): number {
  if (coupon && coupon.amount_off) {
    const denominator = isMonthlyPlan(plan) ? 12 : 1
    return amount - coupon.amount_off / denominator / 100
  }

  if (coupon && coupon.percent_off) {
    return amount - (amount * coupon.percent_off) / 100
  }

  return amount
}

export function findPromoCode(promoCode: string, planData: Array<Plan2>) {
  promoCode = promoCode.toLowerCase().trim()

  for (const plan of planData) {
    if (plan.eligible_coupons !== undefined) {
      const foundCoupon = plan.eligible_coupons.find(
        (coupon: Coupon) => promoCode === coupon.name.toLowerCase()
      )
      if (foundCoupon) {
        return foundCoupon
      }
    }
  }

  return null
}

function getPlanLevel(plan: Plan2) {
  let planKey = PLANS.basic.key

  if (isAnnualPlan(plan)) planKey = PLANS.year.key
  if (isMonthlyPlan(plan)) planKey = PLANS.month.key
  if (isBiAnnualPlan(plan)) planKey = PLANS.biAnnual.key

  return planKey
}

export const getPlanLevel2 = (plan: Plan2) => (isFreePlan(plan) ? 'Bold Basic' : 'Bold Premium')

export const getPlanName = (plan: Plan2) => PLANS[getPlanLevel(plan)].name

const hasFreeTrial = (plan: Plan2) => isAnnualPlan(plan)

export function isPlanFreeTrialEligible(plan: Plan2, currentPlan: Plan2) {
  // we had a previous plan
  // we never want to show a free trial here because we're always in change plan
  if (isPlan(currentPlan)) {
    return false
  }

  // we do not have a previous plan, so we're not changing subscriptions
  // we check to see if what we're selecting has a free trial
  if (isPlan(plan) && hasFreeTrial(plan)) {
    return true
  }

  // we do not have a previous plan and our current plan does not have a free trial, we return false
  return false
}
